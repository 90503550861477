// import Vue from 'vue'
import axios from 'axios'
import { messageBox } from './MessageBox'
import rpc from '@/auth/rpc/ociRpc'
import store from '@/store'
import router from '@/router'
import { generalMethods } from '@/components/General-Functions'
import Configuration from '@/utils/configuration'
import { DeviceUUID } from "device-uuid";

function getEventTime() {
  const dateTime = new Date()
  return {
    __class__: 'datetime',
    year: dateTime.getFullYear(),
    month: dateTime.getMonth() + 1,
    day: dateTime.getDate(),
    hour: dateTime.getHours(),
    minute: dateTime.getMinutes(),
    second: dateTime.getSeconds(),
    microsecond: dateTime.getMilliseconds(),
  }
}
/* eslint-disable */
export const projectsMethods = {
  path: process.env.NODE_ENV === 'production' ? Configuration.value('backendHost') : 'http://localhost:8000/lawmens/',
  loginUser: '',
  loginPw: '',
  loginID: '',
  loginToken: '',
  webToken: '',
  userDetails: '',
  userAssignmentID: -1,
  wizardID: '',
  sessionAuth: '',
  longitude: 0,
  latitude: 0,
  event_time_pc: null,
  callback: null,

  reset() {
    this.siteOperativeId = '';
    this.loginUser = '';
    this.loginPw = '';
    this.loginID = '';
    this.loginToken = '';
    this.webToken = '';
    this.userDetails = '';
    this.userAssignmentID = -4;
    this.wizardID = '';
    this.sessionAuth = ''; 
    this.longitude = 0;
    this.latitude = 0;
    this.event_time_pc = null;
  },

  setSessionAuth() {
    const sAuth1 = String('admin:') + String(this.loginID) + String(':') + String(this.loginToken);
    const sAuth = String(btoa(sAuth1));
    this.sessionAuth = String('Session ') + sAuth;
  },

  GetProjectsList(siteOperativeId, latitude, longitude) {
    this.siteOperativeId = siteOperativeId;
    this.longitude = longitude;
    this.latitude = latitude;
    this.loginID = rpc.getUserId();
    this.loginToken = rpc.getToken();
    this.setSessionAuth();
    this.userDetails = store.getters['app/getUserDetails'];
    this.userAssignmentID = store.getters['app/getUserAssignmentsID'];
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: this.sessionAuth,
      },
    };
    const params = this.userDetails;
    const payload = {
      // jsonrpc: '1.0',
      id: '1',
      method: 'wizard.project.attendance.sign_in.create',
      params: [{ params }],
    };
    axios.post(this.path, payload, config)
      .then((response) => {
        if (response.data['result'] !== undefined) {
          const iterator = response.data['result'].values();
          this.wizardID = iterator.next().value;
          this.CheckInExecute();
        } else if (response.data['error'] !== undefined) {
          messageBox.displayErrorFromData(response.data);
        } else {
          messageBox.displayStandard('Error', 'Unable to Sign in at this time [Create failed]', 'error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

}
/* eslint-enable */
